<template>
  <div>
    <b-card class="card-instagram-overview">
      <b-row>
        <b-col cols="12" class="col-sm-6 col-lg-4 border-right-lg border-right-md">
          <div class="border-bottom pb-2">
              <h3 class="custom-p">{{ $t('instagram.influenceLevel')}}</h3>
              <h2><strong>{{mainData.aqs}} / 100</strong></h2>
              <b-progress height="4px" :value="mainData.aqs" :max="100" />
          </div>

          <div class="mt-4">
              <h3 class="custom-p ">{{ $t('instagram.Er')}}</h3>
              <h2><strong>{{mainData.er.value > 0 ? decimalFixed(mainData.er.value, 2) : decimalFixed(mainData.er.avg, 2)}} %</strong></h2>
          </div>
        </b-col>

        <b-col cols="12" class="col-sm-6 col-lg-4 border-sm-right border-right-lg card-instagram-overview">
          <div class="border-bottom pb-2">
            <h3 class="custom-p">{{ $t('instagram.Estimatedinteractions')}}</h3>
            <h2><strong>{{getFormat(authEngagement())}} / Post</strong></h2>
            <b-progress height="4px" :value="mainData.followers_count" :max="1000" />
          </div>

          <div class="mt-1 border-bottom pb-2">
            <h3 class="custom-p">{{ $t('instagram.followersTooltip') }}</h3>
            <h2><strong>{{getFormat(mainData.followers_count)}}</strong></h2>
          </div>

          <estimate-reach :mainData="mainData" v-if="role !== 'espejo'"/>
        </b-col>

        <b-col cols="12" class="col-sm-6 col-lg-4 border-top-lg mt-2 mt-lg-0">
          <div class="border-bottom pb-2 mt-2 mt-lg-0">
              <h3 class="custom-p">{{ $t('instagram.qualityAudience')}}</h3>
              <h2><strong>{{ typeof aqsFormated === 'number' ? getFormat(aqsFormated) : aqsFormated}}</strong></h2>
              <b-progress height="4px" :value="aqs" :max="100" />
          </div>

          <div class="mt-1" v-if="role !== 'espejo'">
            <h3 class="custom-p">
              {{ $t('profile.estimate_price') }}
              <b-icon
                font-scale="1"
                icon="question-circle"
                class="icon-tooltip"
                id="estimate_price"
              ></b-icon>
              <b-tooltip target="estimate_price" triggers="hover" variant="primary">
                {{$t('profile.estimatedPriceInfo')}}
              </b-tooltip>
            </h3>
            <b-row>
              <b-col cols="6" class="text-center">
                <h4 v-if="price_post > 0"><strong>${{numberFormat(price_post)}} USD</strong></h4>
                <h4 v-else><strong>{{ $t('notAvailable') }}</strong></h4>
                <p class="notas">{{$t('profile.Publication')}}</p>
              </b-col>

              <b-col cols="6" class="text-center">
                <h4 v-if="price_story > 0"><strong>${{numberFormat(price_story)}} USD</strong></h4>
                <h4 v-else><strong>{{ $t('notAvailable') }}</strong></h4>
                <p class="notas">Story</p>
              </b-col>
            </b-row>
            <hr/>
            <div>
              <h3 class="custom-p">
                {{$t('profile.fakeFollowers')}}
                <b-icon
                font-scale="1"
                icon="question-circle"
                class="icon-tooltip"
                id="fake_followers"
              ></b-icon>
              <b-tooltip target="fake_followers" triggers="hover" variant="primary">
                {{$t('profile.tooltipFakeFollowers')}}
              </b-tooltip>
              </h3>
         
              <b-col cols="6" class="container-percentage">
                 <span :class="badgeColor(totalFollowersFake())" :variant="badgeColor(totalFollowersFake())">{{ totalFollowersFake() }}%</span>
              </b-col>
            </div>
          </div>
          <estimate-reach :mainData="mainData" v-else />
        </b-col>

        <b-col cols="12" class="border-top mt-2 pt-2" v-if="mainData.advertising_data">
          <h4><strong>{{ $t('profile.brandMentions') }}</strong></h4>
          <b-row>
            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3" v-if="mainData.advertising_data">
              <h3 class="custom-p">
                {{ $t('profile.marketingPerformance') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="marketingPerformance"
                ></b-icon>
                <b-tooltip target="marketingPerformance" triggers="hover" variant="primary">
                  {{$t('profile.tooltipRendiment')}}
                </b-tooltip>
              </h3>
              <div class="d-flex centerContent">
                <div class="circle-alcance aprovado"></div>
                <h4 class="ml-1 mb-0">
                  <strong>{{$t(`instagram.${mainData.advertising_data.avg_er_display[1]}`)}}</strong>
                </h4>
              </div>
            </b-col>

            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3 mt-3 mt-lg-0" v-if="mainData.advertising_data">
              <h3 class="custom-p">
                {{ $t('profile.postFrequency') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="postFrequencyInfo"
                ></b-icon>
                <b-tooltip target="postFrequencyInfo" triggers="hover" variant="primary">
                  {{$t('profile.postFrequencyInfo')}}
                </b-tooltip>
              </h3>
              <div class="d-flex centerContent">
                <div class="circle-alcance promedio"></div>
                <h4 class="ml-2 mb-0">
                  <strong>{{$t(`instagram.${mainData.advertising_data.all_posts.display[1]}`)}}</strong>
                </h4>
              </div>
            </b-col>

            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3 mt-3 mt-lg-0" v-if="mainData.advertising_data">
              <h3 class="custom-p">
                {{ $t('profile.postFrequencyCommercial') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="postFrequencyCommercialInfo"
                ></b-icon>
                <b-tooltip target="postFrequencyCommercialInfo" triggers="hover" variant="primary">
                  {{$t('profile.postFrequencyCommercialInfo')}}
                </b-tooltip>
              </h3>

              <div class="d-flex centerContent">
                <div class="circle-alcance promedio"></div>
                <h4 class="ml-2 mb-0">
                  <strong>{{$t(`instagram.${mainData.advertising_data.ad_posts.display[1]}`)}}</strong>
                </h4>
              </div>
            </b-col>

            <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3 mt-3 mt-lg-0" v-if="mainData.advertising_data">
              <h3 class="custom-p">
                {{ $t('profile.brandMentions') }}
                <b-icon
                  font-scale="1"
                  icon="question-circle"
                  class="icon-tooltip"
                  id="brandMentionsInfo"
                ></b-icon>
                <b-tooltip target="brandMentionsInfo" triggers="hover" variant="primary">
                  {{$t('profile.brandMentionsInfo')}}
                </b-tooltip>
              </h3>
              <div class="d-flex centerContent">
                <h4 class="mb-0"><strong>{{mainData.advertising_data.brands_mentions_count}} {{ $t('brand') }}</strong></h4>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="brands.length > 0 && brands_convert === brands.length">
      <vue-horizontal responsive class="horizontal" :displacement="0.3" snap="center">
        <b-col
          v-for="(brand, index) in brands"
          :key="index"
          class="d-flex"
        >
          <b-card no-body @click="can_reload ? reloadInfo() : null">
            <div class="p-1 text-center cursor-pointer">
              <b-img
                @click="can_reload ? reloadInfo() : null"
                :src="brand.image_brand" 
                class="responsive rounded-lg w-100 img-150"
                @error="errorLoad(brand, 'update_image')" 
                :key="update_image"
              ></b-img>
              <h5 class="my-1">@{{brand.username}}</h5>
              <div class="d-flex justify-content-between flex-wrap border-top">
                <p class="text-gray notas mt-1">{{brand.mentions_count}} {{ $t('tiktok.mentions') }}</p>
                <span class="mt-1">
                  <p class="text-gray notas m-0">
                    TP: {{brand.mention_er}}%
                    <b-icon
                      font-scale="1"
                      icon="question-circle"
                      class="icon-tooltip"
                      :id="'estimatedScopeInfo_' + index"
                    ></b-icon>
                    <b-tooltip :target="'estimatedScopeInfo_' + index" triggers="hover" variant="primary">
                      {{$t('profile.estimatedScopeInfo')}}
                    </b-tooltip>
                  </p>
                </span>
              </div>
            </div>
          </b-card>
        </b-col>
      </vue-horizontal>
    </div>
  </div>
</template>

<script>
import { 
  BCard, 
  BRow, 
  BCol, 
  BProgress, 
  BIcon, 
  BTooltip, 
  VBToggle, 
  BImg,
} from 'bootstrap-vue'
import EstimateReach from './EstimateReach.vue';
import VueHorizontal from 'vue-horizontal';
import { decimalFixed, getFormat, numberFormat, urlImagetoBase64 } from '@/libs/utils/formats';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BProgress,
    BIcon,
    BTooltip,
    BImg,
    EstimateReach,
    VueHorizontal,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    mainData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      urlImagetoBase64,
      numberFormat,
      getFormat,
      decimalFixed,
      role: 'brand',
      brand_images: [],
      brands: this.mainData.advertising_data && this.mainData.advertising_data.brands_mentions ? this.mainData.advertising_data.brands_mentions : [],
      other_brand_images: [],
      convert_other_brand: true,
      other_brands_convert: 0,
      brands_convert: 0,
      update_image: false,
      can_reload: false,
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name

    const self = this;
    this.brands.forEach(brand => {
      urlImagetoBase64(brand.avatar, function (result) {
        self.brand_images.push(
          {
            'key': brand.username,
            'image': result
          }
        )
        self.brands_convert = self.brands_convert + 1;
        brand['image_brand'] = self.getImageBrand(brand.username, self.brand_images);
      });
    });
  },
  computed: {
    prices () {
      return this.mainData.estimation.map((i) => {
        const cont = [
          {
            content_type : 'photo',
            qty: 0,
            cpp: i.photo,
            subtotal: 0
          },
          {
            content_type : 'video',
            qty: 0,
            cpp: i.video,
            subtotal: 0
          },
          {
            content_type : 'story',
            qty: 0,
            cpp: i.story,
            subtotal: 0
          },
          {
            content_type : 'streaming',
            qty: 0,
            cpp: i.live,
            subtotal: 0
          }
        ]
        const obj = {network: i.network, content: cont}
        return obj
      })
    },
    aqs() {
      const val = this.mainData.audience_type['infs'] + this.mainData.audience_type['real']
      if (val > 0) return Math.round(val)
      return this.$t('profile.noData')
    },
    aqsFormated() {
      const val = this.mainData.followers_count * this.aqs / 100
      if (this.aqs !== this.$t('profile.noData')) return val
      return this.aqs
    },
    price_post () {
      let price = 0
      if (typeof this.prices === 'string') return price
      this.prices.forEach(function(network) {
        network.content.forEach(function(content) {
          if (content.content_type === 'photo') price = content.cpp
          else if (content.content_type === 'video') price = content.cpp
        });
      });

      return price;
    },
    price_story () {
      let price = 0
      if (typeof this.prices === 'string') return price
      this.prices.forEach(function(network) {
        network.content.forEach(function(content) {
          if (content.content_type === 'story') price = content.cpp
        });
      });

      return price;
    }
  },
  methods: {
    reloadInfo() {
      this.$emit('updateProfile')
    },
    authEngagement () {
      const audienceAuth = parseFloat(this.mainData.audience_authenticity.value)
      const followers = parseFloat(this.mainData.followers_count)
      const er = 3.46
      const result = (er / 100) * followers * (audienceAuth / 100)
      return Math.round(result)
    },
    getImageBrand(brand, images) {
      if (images.length === 0) return ''

      const image = images.find(image => image.key === brand);
      return image.image
    },
    errorLoad(brand, update) {
      brand.image_brand = require('@/assets/images/dashboard/img-default-reload.svg');
      this[update] = !this[update];
      this.can_reload = true
      this.update_image = !this.update_image
    },
    totalFollowersFake() {
      let totalFake = 0
      totalFake = (this.mainData.followers_count - this.aqsFormated) / this.mainData.followers_count * 100
      if (this.aqs !== this.$t('profile.noData')) return Math.round(totalFake)
      return this.$t('profile.noData')
    },
    badgeColor(level) {

      if (level <= 29) return 'success-strong'
      if (level <= 60) return 'warning-strong'
      if (level <= 79) return 'orange-strong'
      if (level <= 100) return 'danger-strong'

      return 'primary'
    }

  }
}
</script>
<style scoped>
.horizontal >>> .v-hl-btn svg {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background: none !important;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, #f8f8f8 66%, #f8f8f8) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, #f8f8f8 66%, #f8f8f8) !important;
  padding-left: 24px !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
  color: rgba(34, 41, 47, 0.5);
}
</style>
<style type="text/css">
  .img-150 {
    width: 150px !important;
    height: 150px !important;
    object-fit: cover;
  }
  .custom-p {
    font-size: 22px;
    color: #626262;
  }
  .notas {
    font-size: 12px;
  }
  .circle-alcance{
    width:15px;
    height:15px;
    border-radius:50%;
  }
  .aprovado{
    background: green;
  }
  .promedio{
      background: orange;
  }
  .peligro {
    background: red;
  }
  .force-shadow {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .text-gray {
    color: gray;
  }
  @media(min-width: 576px) {
    .border-right-md {
      border-right: 1px solid #F0EFF4;
      height: 350px;
    }
  }
   @media(min-width: 992px) {
    .border-right-lg {
      border-right: 1px solid #F0EFF4;
      height: 350px;
    }
  }
   @media(max-width: 992px) {
    .border-top-lg {
      border-top: 1px solid #F0EFF4;
    }
  }
</style>
<style>
.container-percentage {
  padding-left: 0px;
  text-align: start;
  font-size: large;
}
.card-instagram-overview > .card-body,
.card-instagram-overview {
  height: 100% !important;
}
.warning-strong{
  color: #ff9f43 !important;
  font-weight: bolder !important;
  font-size: 1.55rem;
}
.success-strong{
  color:  #28c76f !important;
  font-size: 1.55rem;
  font-weight: bold;
}
.danger-strong{
  color: #ea5455 !important;
  font-weight: bolder !important;
  font-size: 1.55rem;
}
.orange-strong{
  color: #fd7e14 !important;
  font-weight: bolder !important;
  font-size: 1.55rem;
}
</style>
